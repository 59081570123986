import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Sliders } from "react-feather";

export interface IFilterOption {
	id: string;
	preference_type: string;
	title: string;
}

const selectStyles = (selected: boolean, width?: number) => ({
	container: (provided: any) => ({
		...provided,
		width: width || 120,
		height: 30,
		zIndex: 100
	}),
	control: (provided: any, state: any) => ({
		...provided,
		borderWidth: selected ? "1px" : provided.borderWidth,
		borderColor: selected ? "rgb(38, 132, 255)" : provided.borderColor,
		boxShadow: selected ? "0 0 0 1px rgb(38, 132, 255)" : provided.boxShadow,
		minHeight: 30,
		height: 30
	}),
	input: (provided: any) => ({
		...provided,
		minHeight: 21,
		maxHeight: 21,
		margin: 0,
		padding: 0
	}),
	valueContainer: (provided: any) => ({
		...provided,
		minHeight: 20,
		height: 20,
		overflow: "show",
		paddingTop: 0
	}),
	indicatorsContainer: (provided: any) => ({
		...provided,
		minHeight: 20,
		height: 20,
		paddingRight: 0,
		paddingLeft: 0,
		paddingTop: 8
	})
});

interface ISelectFieldProps {
	options: any;
	placeholder: string;
	width?: number;
	onChange: (options: any, type: string) => void;
}

const SelectField: React.FC<ISelectFieldProps> = ({ options, placeholder, width, onChange }) => {
	const [selected, setSelected] = useState<boolean>(false);
	const handleChange = (options: any) => {
		options?.length > 0 ? setSelected(true) : setSelected(false);
		onChange(options, placeholder);
	};

	return (
		<Select
			styles={selectStyles(selected, width)}
			className="ml-2 mr-2"
			classNamePrefix="filter-select"
			options={options}
			placeholder={placeholder}
			controlShouldRenderValue={false}
			isClearable
			isMulti
			hideSelectedOptions={false}
			onChange={(options: any) => handleChange(options)}
		/>
	);
};

interface IPackageFiltersProps {
	filterOptions: any;
	onChange: (options: any) => void;
	showTitle?: boolean;
}

const PackageFilters: React.FC<IPackageFiltersProps> = ({
	filterOptions,
	onChange,
	showTitle = true,
	...props
}) => {
	const [formFilters, setFormFilters] = useState<IFilterOption[]>([]);
	const [endUserBenefitFilters, setEndUserBenefitFilters] = useState<IFilterOption[]>([]);
	const [sizeFilters, setSizeFilters] = useState<IFilterOption[]>([]);
	const [materialFilters, setMaterailFilters] = useState<IFilterOption[]>([]);
	const [fillingProcessFilters, setFillingProcessFilters] = useState<IFilterOption[]>([]);
	const [sustainabilityFilters, setSustainabilityFilters] = useState<IFilterOption[]>([]);

	const [combinedFilters, setCombinedFilters] = useState<IFilterOption[]>([]);

	const mapOptions = (options: IFilterOption[]) => {
		return options.map((option: IFilterOption) => {
			return {
				label: option.title,
				value: option
			};
		});
	};
	const handleFilterChange = (options: any, type: string) => {
		switch (type) {
			case "End User Benefits":
				setEndUserBenefitFilters(options);
				return;
			case "Material":
				setMaterailFilters(options);
				return;
			case "Form":
				setFormFilters(options);
				return;
			case "Size":
				setSizeFilters(options);
				return;
			case "Filling Process":
				setFillingProcessFilters(options);
				return;
			case "Sustainability":
				setSustainabilityFilters(options);
				return;
		}
	};

	useEffect(() => {
		setCombinedFilters(
			sustainabilityFilters.concat(
				materialFilters,
				formFilters,
				sizeFilters,
				fillingProcessFilters,
				endUserBenefitFilters
			)
		);
	}, [
		sustainabilityFilters,
		materialFilters,
		formFilters,
		sizeFilters,
		fillingProcessFilters,
		endUserBenefitFilters
	]);

	useEffect(() => {
		onChange(combinedFilters);
	}, [combinedFilters]);

	return (
		<div className="d-flex flex-row align-items-center mt-3">
			<span className={"filter-title mr-1"} style={{ fontSize: 16 }}>
				<Sliders color={"#657280"} size={24} className={"mr-1"} />
				{showTitle ? "Filters:" : ""}
			</span>
			{filterOptions && (
				<>
					<SelectField
						width={120}
						options={mapOptions(filterOptions["Form"] || [])}
						placeholder="Form"
						onChange={handleFilterChange}
					/>
					<SelectField
						width={140}
						options={mapOptions(filterOptions["Material"] || [])}
						placeholder="Material"
						onChange={handleFilterChange}
					/>
					{/* <SelectField
						width={120}
						options={mapOptions(filterOptions["Size"] || [])}
						placeholder="Size"
						onChange={handleFilterChange}
					/>  */}
					<SelectField
						width={210}
						options={mapOptions(filterOptions["End User Benefits"] || [])}
						placeholder="End User Benefits"
						onChange={handleFilterChange}
					/>
					<SelectField
						width={200}
						options={mapOptions(filterOptions["Filling Process"] || [])}
						placeholder="Filling Process"
						onChange={handleFilterChange}
					/>
					<SelectField
						width={190}
						options={mapOptions(filterOptions["Sustainability"] || [])}
						placeholder="Sustainability"
						onChange={handleFilterChange}
					/>
				</>
			)}
		</div>
	);
};

export default PackageFilters;
