import React, { useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../redux/reducers";
import { FILTER_TYPE } from "../../../interfaces/admin";
import portfolioService from "../../../services/preferenceService";
import { IPreference } from "../../../interfaces/preference";
import userService from "../../../services/userService";
import { Nav, NavItem } from "reactstrap";
import Product from "./product";
import Ingredient from "./ingredient";
import Packaging from "./packaging";
import Other from "./other";
import Upgrade from "../../../components/common/upgrade";

interface IProps {
	product: IPreference[];
	portfolio: IPreference[];
	company: IPreference[];
	category: IPreference[];
	getFilter: (type: FILTER_TYPE, limit: number) => Promise<any>;
	getFilters: () => Promise<any>;
	getUserPreferences: () => Promise<any>;
	preferences: IPreference[];
	user: any;
	updatePreferences: (data: any) => Promise<any>;
	getIngredient: () => Promise<any>;
	getIngredientPreferences: (userId: string) => Promise<any>;
	updateIngredientPreferences: (userId: string, body: any) => Promise<any>;
	getPackaging: () => Promise<any>;
	getPackagingPreferences: (userId: string) => Promise<any>;
	updatePackagingPreferences: (userId: string, body: any) => Promise<any>;
	getProduct: () => Promise<any>;
	getProductPreferences: (userId: string) => Promise<any>;
	updateProductPreferences: (userId: string, body: any) => Promise<any>;
}

const TABS = [
	{
		key: "product",
		name: "Product Preferences"
	},
	{
		key: "ingredient",
		name: "Ingredient Preferences"
	},
	{
		key: "packaging",
		name: "Packaging Preferences"
	},
	{
		key: "other",
		name: "Other"
	}
];

const Preferences: React.FC<IProps> = ({
	user,
	getPackaging,
	getPackagingPreferences,
	updatePackagingPreferences,
	getIngredient,
	getIngredientPreferences,
	updateIngredientPreferences,
	getProduct,
	getProductPreferences,
	updateProductPreferences
}) => {
	const [activeTab, setActiveTab] = useState<string>(TABS[0].key);

	return (
		<>
			<div className="tab-navigation" style={{ marginBottom: 22 }}>
				<Nav tabs>
					{TABS.map((t) => (
						<NavItem
							key={t.key}
							className={`nav-link h5 mb-0 ${t.key === activeTab ? "active" : ""}`}
							style={{ cursor: t.key !== activeTab ? "pointer" : "default" }}
							onClick={() => setActiveTab(t.key)}
						>
							{t.name}
						</NavItem>
					))}
				</Nav>
			</div>
			{user?.profile?.company?.subscription_type == "supplier" ? (
				<div className={"mt-5"}>
					<Upgrade />
				</div>
			) : (
				<>


					{activeTab === "product" && (
						<Product
							user={user}
							getProduct={getProduct}
							getProductPreferences={getProductPreferences}
							updateProductPreferences={updateProductPreferences}
						/>
					)}
					{activeTab === "ingredient" && (
						<Ingredient
							user={user}
							getIngredient={getIngredient}
							getIngredientPreferences={getIngredientPreferences}
							updateIngredientPreferences={updateIngredientPreferences}
						/>
					)}
					{activeTab === "packaging" && (
						<Packaging
							user={user}
							getPackaging={getPackaging}
							getPackagingPreferences={getPackagingPreferences}
							updatePackagingPreferences={updatePackagingPreferences}
						/>
					)}
					{activeTab === "other" && <Other />}
				</>
			)}
		</>
	);
};

const mapStateToProps = ({
	preference: { company, product, portfolio, category, preferences },
	user
}: IApplicationState) => ({
	portfolio,
	product,
	company,
	category,
	preferences,
	user
});

const mapDispatchToProps = {
	getFilters: () => portfolioService.getFilters(),
	getFilter: (preferenceType: FILTER_TYPE, limit: number) =>
		portfolioService.getFilter(preferenceType, limit),
	getIngredient: () => portfolioService.getIngredients(),
	getIngredientPreferences: (userId: string) => portfolioService.getIngredientPreferences(userId),
	updateIngredientPreferences: (userId: string, body: any) =>
		portfolioService.updateIngredientPreferences(userId, body),
	getPackaging: () => portfolioService.getPackaging(),
	getPackagingPreferences: (userId: string) => portfolioService.getPackagingPreferences(userId),
	updatePackagingPreferences: (userId: string, body: any) =>
		portfolioService.updatePackagingPreferences(userId, body),
	getProduct: () => portfolioService.getProduct(),
	getProductPreferences: (userId: string) => portfolioService.getProductPreferences(userId),
	updateProductPreferences: (userId: string, body: any) =>
		portfolioService.updateProductPreferences(userId, body),
	getUserPreferences: () => userService.getPreferences(),
	updatePreferences: (preferences: any) => userService.updatePreferences(preferences)
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
