import React from "react";
import SuitabilityBar from "../../../../components/suitabilityBar";
// import packageImagePlaceholder from "../../../assets/images/product-packaging-placeholder.png";
import packageImagePlaceholder from "../../../../assets/images/product-packaging-placeholder.png"

export const DemoPackages: React.FC = () => {
    return (
        <>
        <div style={{ marginRight: '20px', marginBottom: '20px', cursor: 'pointer' }}>
            <div className="persona mini" style={{ flexDirection: 'column' }}>
                <div className="d-flex flex-column" style={{ width: '100%' }}>
                    <img src={packageImagePlaceholder} alt="" style={{ borderRadius: '0' }}/>
                    <div className="product-info flex-grow-1">
                        <h4 className="mb-0" style={{ fontSize: "16px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '170px' }}>{ 'EasyPeel' }</h4>
                        <div className="stats mt-3 mb-2">
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Market: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'Global' }</p>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Material: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'PET' }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%'}}>
                    <SuitabilityBar type={'packaging'} title={"Packaging score"} value={70} />
                </div>
            </div>
        </div>
        <div style={{ marginRight: '20px', marginBottom: '20px', cursor: 'pointer' }}>
            <div className="persona mini" style={{ flexDirection: 'column' }}>
                <div className="d-flex flex-column" style={{ width: '100%' }}>
                    <img src={packageImagePlaceholder} alt="" style={{ borderRadius: '0' }}/>
                    <div className="product-info flex-grow-1">
                        <h4 className="mb-0" style={{ fontSize: "16px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '170px' }}>{ 'OmniPack' }</h4>
                        <div className="stats mt-3 mb-2">
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Market: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'North America' }</p>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Material: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'PET' }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%'}}>
                    <SuitabilityBar type={'packaging'} title={"Packaging score"} value={70} />
                </div>
            </div>
        </div>
        <div style={{ marginRight: '20px', marginBottom: '20px', cursor: 'pointer' }}>
            <div className="persona mini" style={{ flexDirection: 'column' }}>
                <div className="d-flex flex-column" style={{ width: '100%' }}>
                    <img src={packageImagePlaceholder} alt="" style={{ borderRadius: '0' }}/>
                    <div className="product-info flex-grow-1">
                        <h4 className="mb-0" style={{ fontSize: "16px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '170px' }}>{ '20oz Inverted Multilayer PET' }</h4>
                        <div className="stats mt-3 mb-2">
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Market: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'North America' }</p>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Material: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'PET' }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%'}}>
                    <SuitabilityBar type={'packaging'} title={"Packaging score"} value={70} />
                </div>
            </div>
        </div>
        <div style={{ marginRight: '20px', marginBottom: '20px', cursor: 'pointer' }}>
            <div className="persona mini" style={{ flexDirection: 'column' }}>
                <div className="d-flex flex-column" style={{ width: '100%' }}>
                    <img src={packageImagePlaceholder} alt="" style={{ borderRadius: '0' }}/>
                    <div className="product-info flex-grow-1">
                        <h4 className="mb-0" style={{ fontSize: "16px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '170px' }}>{ '12oz Hot Fill Ring Neck' }</h4>
                        <div className="stats mt-3 mb-2">
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Market: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'Global' }</p>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Material: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'PET' }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%'}}>
                    <SuitabilityBar type={'packaging'} title={"Packaging score"} value={70} />
                </div>
            </div>
        </div>
        <div style={{ marginRight: '20px', marginBottom: '20px', cursor: 'pointer' }}>
            <div className="persona mini" style={{ flexDirection: 'column' }}>
                <div className="d-flex flex-column" style={{ width: '100%' }}>
                    <img src={packageImagePlaceholder} alt="" style={{ borderRadius: '0' }}/>
                    <div className="product-info flex-grow-1">
                        <h4 className="mb-0" style={{ fontSize: "16px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '170px' }}>{ '15oz Easy Squeeze and Pour' }</h4>
                        <div className="stats mt-3 mb-2">
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Market: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'North America' }</p>
                            </div>
                            <div className="" style={{ flex: 1 }}>
                            <p className="type" style={{ fontSize: '11px', letterSpacing: '0.39px' }}>Material: </p>
                            <p className="value" style={{ color: '#657280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px'  }}>{ 'PP' }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%'}}>
                    <SuitabilityBar type={'packaging'} title={"Packaging score"} value={70} />
                </div>
            </div>
        </div>
        </>
    )
}

export default DemoPackages