import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import { Box } from "react-feather";
import Button from "../../../components/common/button";

interface IProps {
	user: any;
	getIngredient: () => Promise<any>;
	getIngredientPreferences: (userId: string) => Promise<any>;
	updateIngredientPreferences: (userId: string, body: any) => Promise<any>;
}

interface IPreference {
	preference_type: string;
	title: string;
	id: string;
}

const Ingredient: React.FC<IProps> = (props) => {
	const [list, setList] = useState<any>({});
	const [prefs, setPrefs] = useState<any>({});
	const [saving, setSaving] = useState<boolean>(false);

	useEffect(() => {
		setSaving(true);
		props
			.getIngredient()
			.then((data) => {
				console.log("DATA", data)
				const temp = data.reduce((d: any, a: any) => {
					d[a.preference_type] = d[a.preference_type] || [];
					d[a.preference_type].push(a);
					return d;
				}, Object.create(null));
				setList(temp);
			})
			.then(() => {
				props.getIngredientPreferences(props.user?.user_id).then((data) => {
					setPrefs(data || []);
				});
			})
			.finally(() => setSaving(false));
	}, []);

	const onSelectPref = (pref: IPreference) => {
		const index = (prefs.preferences || []).findIndex((o: IPreference) => o.id === pref.id);
		const temp = { ...prefs };
		if (index > -1) {
			if (temp && temp.preferences) temp.preferences.splice(index, 1);
		} else {
			temp.preferences = [...(temp.preferences || []), pref];
		}
		setPrefs(temp);
	};

	const onSave = () => {
		setSaving(true);
		props.updateIngredientPreferences(props.user?.user_id, prefs).finally(() => setSaving(false));
	};

	return (
		<>
			{Object.keys(list).map((key, index) => (
				<div style={{ borderBottom: "1px dashed #cedbe9", paddingBottom: 15, paddingTop: 11 }}>
					<Label className="color-battleship-grey">{key}</Label>{" "}
					<div>
						{list[key].map((s: IPreference) => (
							<React.Fragment key={s.id}>
								{(prefs.preferences || []).findIndex((p: any) => p.id === s.id) > -1 ? (
									<div
										style={{
											display: "inline-block",
											alignSelf: "center",
											padding: 5,
											borderRadius: 8,
											marginRight: 6,
											marginBottom: 6,
											backgroundColor: "#3f65f1",
											border: "2px solid #3f65f1",
											cursor: "pointer",
											color: "#fff"
										}}
										onClick={() => onSelectPref(s)}
									>
										<Box color="#fff" /> {s.title}
									</div>
								) : (
									<div
										style={{
											display: "inline-block",
											alignSelf: "center",
											padding: 5,
											borderRadius: 8,
											marginRight: 6,
											marginBottom: 6,
											border: "2px solid #cedbe9",
											cursor: "pointer"
										}}
										onClick={() => onSelectPref(s)}
									>
										<Box color="#3f65f1" /> {s.title}
									</div>
								)}
							</React.Fragment>
						))}
					</div>
				</div>
			))}
			<div style={{ paddingTop: 17 }}>
				<p>
					These preferences will help tailor our product suggestions and filter your <br />
					live searching whilst using the app.
				</p>
				<Button
					className="btn btn-primary color-white ls-5 font-weight-semibold mt-3"
					type="submit"
					disabled={saving}
					onClick={() => onSave()}
				>
					Save account settings
				</Button>
			</div>
		</>
	);
};

export default Ingredient;


// import React, { useState, useEffect } from "react";
// import { Label } from "reactstrap";
// import { Box } from "react-feather";
// import Button from "../../../components/common/button";

// interface IProps {
// 	user: any;
// 	getIngredient: () => Promise<any>;
// 	getIngredientPreferences: (userId: string) => Promise<any>;
// 	updateIngredientPreferences: (userId: string, body: any) => Promise<any>;
// }

// interface IPreference {
// 	preference_type: string;
// 	title: string;
// 	id: string;
// }

// const Ingredient: React.FC<IProps> = (props) => {
// 	const [list, setList] = useState<any>({});
// 	const [prefs, setPrefs] = useState<any>({});
// 	const [saving, setSaving] = useState<boolean>(false);

// 	useEffect(() => {
// 		setSaving(true);

// 		// Attempt to fetch ingredients
// 		props
// 			.getIngredient()
// 			.then((data) => {
// 				if (data && data.length > 0) {
// 					const formattedData = data.reduce((acc: any, item: IPreference) => {
// 						acc[item.preference_type] = acc[item.preference_type] || [];
// 						acc[item.preference_type].push(item);
// 						return acc;
// 					}, {});
// 					setList(formattedData);
// 				} else {
// 					// Use dummy data if API returns empty or undefined
// 					const dummyData: IPreference[] = [
// 						{ preference_type: "Spices", title: "Salt", id: "1" },
// 						{ preference_type: "Spices", title: "Pepper", id: "2" },
// 						{ preference_type: "Vegetables", title: "Carrot", id: "3" },
// 						{ preference_type: "Vegetables", title: "Broccoli", id: "4" }
// 					];
// 					const tempList = dummyData.reduce((acc: any, item: IPreference) => {
// 						acc[item.preference_type] = acc[item.preference_type] || [];
// 						acc[item.preference_type].push(item);
// 						return acc;
// 					}, {});
// 					setList(tempList);
// 				}
// 			})
// 			.catch(() => {
// 				// Use dummy data on API error
// 				const dummyData: IPreference[] = [
// 					{ preference_type: "Spices", title: "Salt", id: "1" },
// 					{ preference_type: "Spices", title: "Pepper", id: "2" },
// 					{ preference_type: "Vegetables", title: "Carrot", id: "3" },
// 					{ preference_type: "Vegetables", title: "Broccoli", id: "4" }
// 				];
// 				const tempList = dummyData.reduce((acc: any, item: IPreference) => {
// 					acc[item.preference_type] = acc[item.preference_type] || [];
// 					acc[item.preference_type].push(item);
// 					return acc;
// 				}, {});
// 				setList(tempList);
// 			})
// 			.finally(() => setSaving(false));

// 		// Fetch user preferences
// 		props.getIngredientPreferences(props.user?.user_id).then((data) => {
// 			setPrefs(data || []);
// 		}).finally(() => setSaving(false));
// 	}, []);

// 	const onSelectPref = (pref: IPreference) => {
// 		const index = (prefs.preferences || []).findIndex((o: IPreference) => o.id === pref.id);
// 		const temp = { ...prefs };
// 		if (index > -1) {
// 			temp.preferences.splice(index, 1);
// 		} else {
// 			temp.preferences = [...(temp.preferences || []), pref];
// 		}
// 		setPrefs(temp);
// 	};

// 	const onSave = () => {
// 		setSaving(true);
// 		props.updateIngredientPreferences(props.user?.user_id, prefs).finally(() => setSaving(false));
// 	};

// 	return (
// 		<>
// 			{Object.keys(list).map((key, index) => (
// 				<div key={index} style={{ borderBottom: "1px dashed #cedbe9", paddingBottom: 15, paddingTop: 11 }}>
// 					<Label className="color-battleship-grey">{key}</Label>
// 					<div>
// 						{list[key].map((s: IPreference) => (
// 							<React.Fragment key={s.id}>
// 								{(prefs.preferences || []).findIndex((p: IPreference) => p.id === s.id) > -1 ? (
// 									<div
// 										style={{
// 											display: "inline-block",
// 											alignSelf: "center",
// 											padding: 5,
// 											borderRadius: 8,
// 											marginRight: 6,
// 											marginBottom: 6,
// 											backgroundColor: "#3f65f1",
// 											border: "2px solid #3f65f1",
// 											cursor: "pointer",
// 											color: "#fff"
// 										}}
// 										onClick={() => onSelectPref(s)}
// 									>
// 										<Box color="#fff" /> {s.title}
// 									</div>
// 								) : (
// 									<div
// 										style={{
// 											display: "inline-block",
// 											alignSelf: "center",
// 											padding: 5,
// 											borderRadius: 8,
// 											marginRight: 6,
// 											marginBottom: 6,
// 											border: "2px solid #cedbe9",
// 											cursor: "pointer"
// 										}}
// 										onClick={() => onSelectPref(s)}
// 									>
// 										<Box color="#3f65f1" /> {s.title}
// 									</div>
// 								)}
// 							</React.Fragment>
// 						))}
// 					</div>
// 				</div>
// 			))}
// 			<div style={{ paddingTop: 17 }}>
// 				<p>
// 					These preferences will help tailor our product suggestions and filter your <br />
// 					live searching whilst using the app.
// 				</p>
// 				<Button
// 					className="btn btn-primary color-white ls-5 font-weight-semibold mt-3"
// 					type="submit"
// 					disabled={saving}
// 					onClick={() => onSave()}
// 				>
// 					Save account settings
// 				</Button>
// 			</div>
// 		</>
// 	);
// };

// export default Ingredient;

