import React, { useEffect, useState } from "react";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import Brand from "./components/brand/";
import Markets from "./components/markets";
import CompanyTab from "./components/company";
import userServices from "../../../services/userService";
import companyServices from "../../../services/companyService";
import { IUser } from "../../../interfaces/user";
import Loader from "../../../components/loader";
import Button from "../../../components/common/button";
import { ListGroup, ListGroupItem, Input, Table, Nav, NavItem } from "reactstrap";
import userService from "../../../services/userService";
import { Trash2, User } from "react-feather";
import { ICompany } from "../../../interfaces/company";
import productTypeService from "../../../services/productTypeService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { History } from "history";
import ShareProduct from "./components/shareProduct";
import { useDebounce } from "../../../utils/hooks";
import { EMAIL_REGEX } from "./components/companyValidateModal";
import companyService from "../../../services/companyService";

interface IProps extends RouteComponentProps<{ tab: string }> {
	users: IUser[];
	role: string;
	company: any;
	getAllCompanyUsers: () => Promise<any>;
	addCompanyUser: (email: string) => Promise<any>;
	updateUserRole: (userId: string, role: string) => Promise<any>;
	removeUser: (userId: string) => Promise<any>;
	updateCompanyPlantBased: (plant_based: string) => Promise<any>;
	updateCompanyTypes: (types: { id: string }[]) => Promise<any>;
	removeCompanyType: (id: string) => Promise<any>;
	history: History;
	getCompanyName: (email: any) => Promise<any>;
}

const TABS = [
	{
		key: "brands",
		name: "Brands"
	},
	{
		key: "markets",
		name: "Markets"
	},
	{
		key: "company",
		name: "Company Type"
	},
	{
		key: "users",
		name: "Users"
	},
	{
		key: "share",
		name: "Share Product"
	}
];

const Company: React.FC<IProps> = ({
	users,
	role,
	company,
	getAllCompanyUsers,
	addCompanyUser,
	updateUserRole,
	removeUser,
	updateCompanyPlantBased,
	removeCompanyType,
	updateCompanyTypes,
	getCompanyName,
	history
}) => {
	const params = new URLSearchParams(history.location.search);
	const tab = params.get("tab");
	const [loading, setLoading] = useState<boolean>(false);
	const [updating, setUpdating] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [activeTab, setActiveTab] = useState<string>(tab ? tab : TABS[0].key);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [isSameCompany, setIsSameCompany] = useState<boolean>(false);

	const debouncedSearchTerm = useDebounce(email, 1000);

	useEffect(() => {
		setLoading(true);
		getAllCompanyUsers().finally(() => {
			setLoading(false);
		});
	}, []);

	const onAddCompanyUser = () => {
		setLoading(true);
		addCompanyUser(email).finally(() => {
			setEmail("");
			getAllCompanyUsers().finally(() => {
				setLoading(false);
			});
		});
	};

	const onUserRoleChanged = (userId: string, role: string) => {
		setLoading(true);
		updateUserRole(userId, role).finally(() => {
			getAllCompanyUsers().finally(() => {
				setLoading(false);
			});
		});
	};

	const onUserRemove = (userId: string) => {
		setLoading(true);
		removeUser(userId).finally(() => {
			getAllCompanyUsers().finally(() => {
				setLoading(false);
			});
		});
	};

	const validateEmail = (emailInput: any) => {
		console.log(email);
		setIsSameCompany(false);
		setIsEmailValid(false);
		if (EMAIL_REGEX.test(emailInput)) {
			setEmail(emailInput);
			setIsEmailValid(true);
		}
	};

	useEffect(() => {
		if (isEmailValid) {
			console.log("TRIGGER IS USE EFFECT")
			getCompanyName(email).then((res: any) => {
				res && res.id && setIsSameCompany(res.name === company);
			});
		}
	}, [debouncedSearchTerm, isEmailValid]);
	return (
		<div style={{ height: "100%" }}>
			<div className="tab-navigation" style={{ marginBottom: 22 }}>
				<Nav tabs>
					{TABS.map((t) => {
						return role === "JF_ADMIN" ? (
							t.name !== "Share Product" && (
								<NavItem
									key={t.key}
									className={`nav-link h5 mb-0 ${t.key === activeTab ? "active" : ""}`}
									style={{ cursor: t.key !== activeTab ? "pointer" : "default" }}
									onClick={() => setActiveTab(t.key)}
								>
									{t.name}
								</NavItem>
							)
						) : (
							<NavItem
								key={t.key}
								className={`nav-link h5 mb-0 ${t.key === activeTab ? "active" : ""}`}
								style={{ cursor: t.key !== activeTab ? "pointer" : "default" }}
								onClick={() => setActiveTab(t.key)}
							>
								{t.name}
							</NavItem>
						);
					})}
				</Nav>
			</div>
			{activeTab === "brands" && <Brand />}
			{activeTab === "markets" && <Markets />}
			{activeTab === "company" && (
				<CompanyTab
					removeCompanyType={removeCompanyType}
					updateCompanyTypes={updateCompanyTypes}
					updateCompanyPlantBased={updateCompanyPlantBased}
				/>
			)}
			{activeTab === "users" && (
				<Loader isLoading={loading}>
					<div className="company-users">
						{role !== "COMPANY_USER" && (
							<ListGroup>
								<ListGroupItem>
									<div className="d-flex invite-user">
										<Input
											placeholder="User Email"
											className="px-2"
											onChange={(e) => validateEmail(e.target.value)}
										/>
										<Button
											onClick={onAddCompanyUser}
											disabled={loading || email === "" || !isEmailValid || !isSameCompany}
											className="color-white"
										>
											Invite User
										</Button>
									</div>
									{isEmailValid && (
										<p
											style={{
												marginBottom: "0",
												paddingBottom: "0",
												fontSize: "12px",
												color: isSameCompany ? "green" : "red"
											}}
										>
											{isSameCompany && isEmailValid
												? "*Valid Email Address"
												: "*Sorry , the entered email does not belong to your company."}
										</p>
									)}
								</ListGroupItem>
							</ListGroup>
						)}

						<Table borderless>
							{/* <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>ROLE</th>
                                    <th>Approved</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                    <th></th>
                                </tr>
                            </thead> */}
							<tbody>
								{users.map((user: IUser) => {
									return (
										<tr
											className={user.disable ? "text-muted" : ""}
											style={{ borderBottom: "1px solid #cedbe9" }}
										>
											<td style={{ paddingTop: 25 }}>
												<User style={{ marginRight: 20 }} />
												{user.first_name} {user.last_name}
											</td>
											<td style={{ paddingTop: 25 }}>{user.email}</td>
											{/* <td style={{paddingTop: 25}}>{user.role}</td> */}
											<td>
												{role !== "COMPANY_USER" ? (
													<div className="role-dropdown">
														<select
															value={user.role}
															onChange={(e) => {
																onUserRoleChanged(user.id, e.target.value);
															}}
															className="form-control"
														>
															<option value="COMPANY_ADMIN">COMPANY_ADMIN</option>
															<option value="COMPANY_USER">COMPANY USER</option>
														</select>
													</div>
												) : (
													<td style={{ paddingTop: 25 }}>{user.role}</td>
												)}
											</td>
											{/* <td>{user.is_active ? 'Yes' : 'No' }</td> */}
											{/* <td>{user.disable ? 'Disabled' : 'Enabled'}</td> */}
											<td style={{ paddingTop: 25 }}>{user.company?.name}</td>
											{/* <td>{moment(user.created_at).format('MMM DD YYYY')}</td> */}
											{role !== "COMPANY_USER" && (
												<td style={{ textAlign: "right" }}>
													<Button
														onClick={() => onUserRemove(user.id)}
														className="btn-danger text-white"
													>
														<Trash2 size={14} />
													</Button>
												</td>
											)}
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				</Loader>
			)}
			{activeTab === "share" && <ShareProduct history={history} />}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	users: state.user.users,
	role: state.user.role,
	company: state.user?.profile?.company?.name
});

const mapDispatchToProps = {
	getAllCompanyUsers: () => userServices.getAllCompanyUsers(),
	addCompanyUser: (email: string) => userService.addCompanyUser(email),
	updateUserRole: (userId: string, role: string) => userService.updateUserRole(userId, role),
	removeUser: (userId: string) => userService.removeUser(userId),
	updateCompanyPlantBased: (plant_based: string) =>
		companyServices.updateCompanyPlantBased(plant_based),
	updateCompanyTypes: (types: { id: string }[]) => companyServices.updateCompanyType(types),
	removeCompanyType: (id: string) => companyServices.removeCompanyType(id),
	getCompanyName: (email: any) => companyService.getCompanyName(email)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Company));
